import * as React from 'react'
import { useTranslation } from 'react-i18next'
import NavbarComponent from '../../components/Navbar/Navbar.component'
import FooterComponent from '../../components/Footer/Footer.component'
import HeaderComponent from '../../components/Header/Header.component'
import EvenementPvComponent from '../../components/Evenements/Evenement_pv.component'

function EvenementsPv() {
  const { t } = useTranslation()
  return (
    <div>
      <NavbarComponent />
      <div>
        <HeaderComponent title={t('event_pv_title')} />
        <EvenementPvComponent />
      </div>
      <FooterComponent />
    </div>
  )
}

export default EvenementsPv
