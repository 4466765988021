import { useState } from 'react'

export default function useFormulaire() {
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [objet, setObject] = useState('')
  const [about, setAbout] = useState('')

  const [notif, setNotif] = useState(false)

  function sendRequest() {
    fetch(`http://localhost:3001/api/contact`, {
      method: 'POST',
      body: JSON.stringify({ name, mail, objet, about }), // Les paramètres sont inclus ici dans le corps de la requête
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(() => setNotif(true))
  }

  return { setName, setMail, setObject, setAbout, notif, setNotif, sendRequest }
}
