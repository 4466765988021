import * as React from 'react'
import { useTranslation } from 'react-i18next'
import GlobalPage from '../GlobalPage/GlobalPage.component'
import HebergementsContent from '../../components/HebergmentsContent/HebergementContent.component'

function HebergementsPage() {
  const { t } = useTranslation()
  return (
    <GlobalPage title={t('hebergements_title')}>
      <HebergementsContent />
    </GlobalPage>
  )
}

export default HebergementsPage
