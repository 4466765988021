import * as React from 'react'
import { Button, Carousel } from 'flowbite-react'
import './home.page.css'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import NavbarComponent from '../../components/Navbar/Navbar.component'
import FooterComponent from '../../components/Footer/Footer.component'
import HebergementsContent from '../../components/HebergmentsContent/HebergementContent.component'
import SallesContent from '../../components/SallesContent/SallesContent.component'
import ServicesActivitesContent from '../../components/ServicesActivitesContent/ServicesActivitesContent.component'
import EvenementProComponent from '../../components/Evenements/Evenement_pro.component'
import EvenementPvComponent from '../../components/Evenements/Evenement_pv.component'

function Home() {
  const { t } = useTranslation()
  return (
    <div>
      <NavbarComponent />
      <div>
        <div className="home-page-background">
          <motion.div
            className="home-page-content"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <img src="/images/Binson-logo-white.png" className="max-h-60 mb-10" alt="imagecenter" />

            <p className="max-w-3xl text-center text-white mb-10 mx-5">{t('home_center_first_description')}</p>
            <p className="max-w-3xl text-center text-white mx-5">{t('home_center_second_description')}</p>

            <Button name="contact-button" href="/contact" color="primary" className="mt-5">
              {t('home_contact_button')}
            </Button>
          </motion.div>
        </div>
        <motion.div
          className="home-page-content"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="grid gric-cols-1 md:grid-cols-2 m-20 container mx-auto px-5">
            <div className="flex flex-col justify-center gap-5 mr-5 mb-20 md:mb-0">
              <h1 className="text-xl font-bold text-stone-600">{t('home_who_we_are')}</h1>
              <h2 className="text-3xl font-bold">{t('home_welcome_message')}</h2>
              <p className="text-justify">{t('home_first_paragraph')}</p>
              <p className="text-justify">{t('home_second_paragraph')}</p>
              <p className="text-justify">{t('home_third_paragraph')}</p>
            </div>
            <div className="grid grid-cols-3 my-5">
              <img className="col-start-1 col-span-2" src="./images/prieuré_home_old.jpg" alt="test 1" />
              <img className="col-start-2 col-span-2" src="./images/prieuré_home_new.jpg" alt="test 1" />
            </div>
          </div>
        </motion.div>
        <Carousel className="md:w-3/5 mx-auto" style={{ height: '450px' }}>
          <img src="./images/carousel/carousel1.jpg" alt="test 1" />
          <img src="./images/carousel/carousel2.jpg" alt="test 1" />
          <img src="./images/carousel/carousel3.jpg" alt="test 1" />
        </Carousel>
        <div className="">
          <h2 className="text-3xl font-bold px-5 text-center border-t-2 mt-20 pt-5">{t('home_title_service_activite')}</h2>
          <ServicesActivitesContent />

          <h2 className="text-3xl font-bold px-5 text-center border-t-2 pt-5 mt-10">{t('home_title_hergement')}</h2>
          <HebergementsContent />

          <h2 className="text-3xl font-bold px-5 sm:mt-10 text-center border-t-2 pt-5">{t('home_title_salles')}</h2>
          <SallesContent />
          <h2 className="text-3xl font-bold px-5 text-center border-t-2 pt-5 mt-10">{t('home_title_event_pro')}</h2>
          <EvenementProComponent />
          <h2 className="text-3xl font-bold px-5 text-center border-t-2 pt-5 mt-10">{t('home_title_event_pv')}</h2>
          <EvenementPvComponent />
        </div>
      </div>

      <FooterComponent />
    </div>
  )
}

export default Home
