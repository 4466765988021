import * as React from 'react'
import { Button, Card, Carousel } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faShower, faToilet, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import BackgroundedSection from '../BackgroundedSection/BackgroundedSection.component'

function HebergementsContent() {
  const { t } = useTranslation()
  return (
    <div>
      <div className="px-5">
        <h1 className="text-xl font-bold text-stone-600 mb-5 mx-auto container mt-10">{t('hebergements_subtitle')}</h1>
        <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto ">
          <img alt="temp imgg3" src="./images/prieuré_hebergement_preview.jpg" />
          <div className="flex flex-col justify-center">
            <div className="text-justify">{t('hebergements_paragraph1')}</div>
            <div className="text-justify">{t('hebergements_paragraph2')}</div>
            <div className="text-justify">{t('hebergements_paragraph3')}</div>
            <div className="text-justify">{t('hebergements_paragraph4')}</div>
            <Button name="contact-button" href={t('hebergement_reserve_url')} className="mt-4" color="primary">
              {t('hebergement_reserve')}
            </Button>
          </div>
        </div>
      </div>

      <BackgroundedSection>
        <div className="mb-5 font-bold mx-auto container">{t('hebergements_highlighted_text')}</div>
        <ul className="list-disc mx-auto container">
          <li>{t('hebergements_list_item1')}</li>
          <li>{t('hebergements_list_item2')}</li>
          <li>{t('hebergements_list_item3')}</li>
          <li>{t('hebergements_list_item4')}</li>
          <li>{t('hebergements_list_item5')}</li>
          <li>{t('hebergements_list_item6')}</li>
        </ul>
      </BackgroundedSection>
      <div className="px-5">
        <div className="text-xl font-bold text-stone-600 mb-5 mx-auto container">{t('hebergement_title_card')}</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-10 text-center gap-10 mx-auto container">
          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faBed} size="2x" className="mb-2" />
            <div>{t('hebergement_texte_card1')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faUsers} size="2x" className="mb-2" />
            <div>{t('hebergement_texte_card2')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faUsers} size="2x" className="mb-2" />
            <div>{t('hebergement_texte_card3')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <div>
              <FontAwesomeIcon icon={faShower} size="2x" className="mb-2 mr-5" />
              <FontAwesomeIcon icon={faToilet} size="2x" className="mb-2" />
            </div>

            <div>{t('hebergement_texte_card4')}</div>
          </Card>
        </div>

        <Carousel className="md:w-3/5 mx-auto" style={{ height: '450px' }}>
          <img src="./images/hebergements/1.jpg" alt="Images description des chambres" />
          <img src="./images/hebergements/2.jpg" alt="Images description" />
          <img src="./images/hebergements/prieure_foyer_accueil.png" alt="test 1" />
          <img src="./images/hebergements/vue_bar_prieure.png" alt="test 1" />
        </Carousel>
      </div>
    </div>
  )
}

export default HebergementsContent
