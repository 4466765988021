import * as React from 'react'
import { useTranslation } from 'react-i18next'
import GlobalPage from '../GlobalPage/GlobalPage.component'
import SallesContent from '../../components/SallesContent/SallesContent.component'

function SallesPage() {
  const { t } = useTranslation()
  return (
    <GlobalPage title={t('salles_title')}>
      <SallesContent />
    </GlobalPage>
  )
}

export default SallesPage
