import * as React from 'react'
import { motion } from 'framer-motion'

import './header.component.css'

function HeaderComponent({ title }: { title: string }) {
  return (
    <div className="header-page-background">
      <motion.div
        className="header-page-content"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <h1 className="uppercase text-3xl font-extrabold text-white shadow-xl text-center">{title}</h1>
      </motion.div>
    </div>
  )
}
export default HeaderComponent
