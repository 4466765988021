import * as React from 'react'
import NavbarComponent from '../../components/Navbar/Navbar.component'
import './Contact.page.css'
import FormulaireComponent from '../../components/Formulaire/Formulaire.component'
import FooterComponent from '../../components/Footer/Footer.component'

function Contact() {
  return (
    <div className="md:h-screen contact-page-content">
      <NavbarComponent />
      <div className="contact-card flex items-center justify-center mt-14">
        <div className="card grid grid-cols-1 md:grid-cols-2 rounded-lg ">
          {/* Formulaire */}
          <div className="card-formulaire p-5 flex flex-auto justify-items-center">
            <FormulaireComponent />
          </div>
          {/* Google map */}
          <div className="card-googlemap flex flex-auto justify-items-center">
            <iframe
              title="map localisation du cite"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2270081.155681056!2d2.728987838641212!3d48.64190012621241!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e91b07eb68947b%3A0x895b07da9c5c87b2!2sTC%20CHATILLON%20SUR%20MARNE!5e0!3m2!1sfr!2sfr!4v1699051488607!5m2!1sfr!2sfr"
              width="100%"
              className="rounded-lg"
              height="100%"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default Contact
