import React from 'react'
import ReactDOM from 'react-dom/client'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import './index.css'
import { CustomFlowbiteTheme, Flowbite } from 'flowbite-react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './pages/Home/Home.page'
import Contact from './pages/Contact/Contact.page'
import Maintenance from './pages/Maitenance/maintenance.page'
import NotFoundPage from './pages/404/404.page'
import * as frTranslation from './translations/fr.json'
import * as enTranslation from './translations/en.json'
import * as deTranslation from './translations/de.json'
import * as nlTranslation from './translations/nl.json'
import * as spTranslation from './translations/sp.json'
import SallesPage from './pages/Salles/Salles.page'
import EvenementsPro from './pages/Evenements/EvenementPro.page'
import EvenementsPv from './pages/Evenements/EvenementPv.page'
import HebergementsPage from './pages/Hebergements/Hebergements.page'
import ServicesActivitesPage from './pages/ServicesActivities/ServicesActivities.page'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const isMaintenance = false

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/hebergements',
    element: <HebergementsPage />,
  },
  {
    path: '/salles',
    element: <SallesPage />,
  },
  {
    path: '/services-activities',
    element: <ServicesActivitesPage />,
  },
  {
    path: '/evenements-pro',
    element: <EvenementsPro />,
  },
  {
    path: '/evenements-pv',
    element: <EvenementsPv />,
  },
])

const maintenanceRouter = createBrowserRouter([
  {
    path: '*',
    element: <Maintenance />,
  },
])

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    de: {
      translation: deTranslation,
    },
    nl: {
      translation: nlTranslation,
    },
    sp: {
      translation: spTranslation,
    },
  },
  fallbackLng: localStorage.getItem('i18nextLng') || 'fr',

  interpolation: {
    escapeValue: false,
  },
})

const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: 'bg-amber-700 hover:bg-amber-800 text-white shadow-xl',
    },
  },
  navbar: {
    root: {
      base: 'bg-white px-2 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4 shadow-xl',
    },
    link: {
      base: 'block py-2 pr-4 pl-3 md:p-0',
      active: {
        on: 'text-amber-700',
        off: 'text-white hover:text-amber-700',
      },
      disabled: {
        on: 'text-gray-400 hover:cursor-not-allowed dark:text-gray-600',
        off: '',
      },
    },
  },
  dropdown: {
    inlineWrapper: 'text-white',
    floating: {
      animation: 'transition-opacity',
      arrow: {
        base: 'absolute z-10 h-2 w-2 rotate-45',
        style: {
          dark: 'bg-gray-900 dark:bg-gray-700',
          light: 'bg-white',
          auto: 'bg-white dark:bg-gray-700',
        },
      },
    },
  },
}

root.render(
  <React.StrictMode>
    <Flowbite theme={{ theme: customTheme }}>
      <RouterProvider router={isMaintenance ? maintenanceRouter : router} />
    </Flowbite>
  </React.StrictMode>,
)
