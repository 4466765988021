import { Dropdown } from 'flowbite-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

function LanguageSelector() {
  const { i18n } = useTranslation()
  type AvailableLanguages = 'fr' | 'en' | 'de' | 'nl' | 'es'
  const [language, setLanguage] = React.useState<AvailableLanguages>(localStorage.getItem('i18nextLng') as AvailableLanguages)

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem('i18nextLng', language)
    setLanguage(i18n.language as AvailableLanguages)
  }

  const flagUrl: { [key in AvailableLanguages]: string } = {
    fr: 'https://flagicons.lipis.dev/flags/4x3/fr.svg',
    en: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
    de: 'https://flagicons.lipis.dev/flags/4x3/de.svg',
    nl: 'https://flagicons.lipis.dev/flags/4x3/nl.svg',
    es: 'https://flagicons.lipis.dev/flags/4x3/es.svg',
  }

  return (
    <Dropdown
      arrowIcon={false}
      inline
      placement="left-start"
      className="w-18"
      label={
        <div className="flex flex-nowrap gap-1 max-h-5">
          <img src={flagUrl[language as keyof typeof flagUrl]} alt="label" /> ▼
        </div>
      }
    >
      <Dropdown.Item onClick={() => changeLanguage('fr')} className="gap-2">
        <img src={flagUrl.fr} alt="Français" className="w-6" />
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('en')} className="gap-2">
        <img src={flagUrl.en} alt="English" />
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('de')} className="gap-2">
        <img src={flagUrl.de} alt="Deutsch" />
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('nl')} className="gap-2">
        <img src={flagUrl.nl} alt="Nederlands" />
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('es')} className="gap-2">
        <img src={flagUrl.es} alt="Spannish" />
      </Dropdown.Item>
    </Dropdown>
  )
}

export default LanguageSelector
