import * as React from 'react'
import { Footer } from 'flowbite-react'
import { useTranslation } from 'react-i18next'

function FooterComponent() {
  const { t } = useTranslation()

  return (
    <Footer container>
      <div className="w-full h-full">
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between mx-auto container">
          <Footer.Copyright href="#" by="Le prieure de binson™" year={2023} />
          <div>
            <p>{t('footer_adresse')}</p>
            <p>{t('footer_telephone')}</p>
            <p>{t('footer_email')}</p>
          </div>
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <div className="flex flex-row gap-2">
              <a href={t('footer_facebook_url')}>
                <img src="/images/icons/facebook.png" alt="logo facebook" className=" h-10 w-10" />
              </a>
              <a href={t('footer_linkedin_url')}>
                <img src="/images/icons/linkedin.png" alt="logo linkedin" className=" h-10 w-10" />
              </a>
              <a href={t('footer_instagram_url')}>
                <img src="/images/icons/instagram.png" alt="logo instagram" className=" h-10 w-10" />
              </a>
              <a href={t('footer_youtube_url')}>
                <img src="/images/icons/youtube.png" alt="logo youtube" className=" h-10 w-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  )
}
export default FooterComponent
