import * as React from 'react'
import NavbarComponent from '../../components/Navbar/Navbar.component'
import './404.page.css'

function NotFoundPage() {
  return (
    <div className="h-screen">
      <NavbarComponent />
      <div className="notfound-page-content">
        <h1 className="uppercase text-3xl font-extrabold text-white shadow-xl ">404 Not Found</h1>
      </div>
    </div>
  )
}

export default NotFoundPage
