import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Carousel } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faBirthdayCake, faUtensils, faTree, faHandsHelping } from '@fortawesome/free-solid-svg-icons'
import BackgroundedSection from '../BackgroundedSection/BackgroundedSection.component'

function EvenementPvComponent() {
  const { t } = useTranslation()

  return (
    <div>
      <div className="px-5">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 my-10 text-center gap-10 mx-auto container">
          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faBed} size="2x" className="mb-2" />
            <div>{t('event_pv_lits')}</div>
            <div>{t('event_pv_lits_desc')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faBirthdayCake} size="2x" className="mb-2" />
            <div>{t('event_pv_fete')}</div>
            <div>{t('event_pv_fete_desc')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faUtensils} size="2x" className="mb-2" />
            <div>{t('event_pv_liberte')}</div>
            <div>{t('event_pv_liberte_desc')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faTree} size="2x" className="mb-2 mr-5" />
            <div>{t('event_pv_balade')}</div>
            <div>{t('event_pv_balade_desc')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faHandsHelping} size="2x" className="mb-2 mr-5" />
            <div>{t('event_pv_service')}</div>
            <div>{t('event_pv_service_desc')}</div>
          </Card>
        </div>
      </div>
      <BackgroundedSection>
        <h1 className="mb-5 font-bold mx-auto container text-center">{t('event_pv_highlighted_title')}</h1>
        <p className="mb-5 mx-auto container text-center">{t('event_pv_highlighted_text')}</p>
      </BackgroundedSection>
      <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto">
        <img alt="temp imgg3" src="./images/prieuré_hebergement_preview.jpg" />
        <div>
          <h1 className="text-xl text-center font-bold text-stone-600 mb-5 mx-auto container mt-10">{t('event_pv_subtitle')}</h1>
          <p className="max-w-3xl text-centermb-10 mx-5 text-center">{t('event_pv_desc1')}</p>
          <p className="max-w-3xl text-centermb-10 mx-5 text-center">{t('event_pv_desc2')}</p>
          <Button name="contact-button" href="/contact" color="primary" className="mt-5">
            {t('home_contact_button')}
          </Button>
        </div>
      </div>
      <Carousel className="md:w-3/5 mx-auto mt-20" style={{ height: '450px' }}>
        <img src="./images/hebergements/1.jpg" alt="Images description des chambres" />
        <img src="./images/hebergements/2.jpg" alt="Images description" />
        <img src="./images/hebergements/prieure_foyer_accueil.png" alt="test 1" />
        <img src="./images/hebergements/vue_bar_prieure.png" alt="test 1" />
      </Carousel>
    </div>
  )
}
export default EvenementPvComponent
