import * as React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useTranslation } from 'react-i18next'
import GlobalPage from '../GlobalPage/GlobalPage.component'
import ServicesActivitesContent from '../../components/ServicesActivitesContent/ServicesActivitesContent.component'

function ServicesActivitesPage() {
  const { t } = useTranslation()

  return (
    <GlobalPage title={t('services_activities_title')}>
      <ServicesActivitesContent />
    </GlobalPage>
  )
}

export default ServicesActivitesPage
