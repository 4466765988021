import * as React from 'react'
import { motion } from 'framer-motion'
import NavbarComponent from '../../components/Navbar/Navbar.component'
import FooterComponent from '../../components/Footer/Footer.component'
import HeaderComponent from '../../components/Header/Header.component'

function GlobalPage({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div>
      <NavbarComponent />
      <div>
        <HeaderComponent title={title} />
        <motion.div
          className="home-page-content"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {children}
          <FooterComponent />
        </motion.div>
      </div>
    </div>
  )
}

export default GlobalPage
