import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Carousel } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faSmoking, faUsers } from '@fortawesome/free-solid-svg-icons'

function SallesContent() {
  const { t } = useTranslation()
  return (
    <div>
      {' '}
      <div className="px-5">
        <h1 className="text-xl font-bold text-stone-600 mb-5 mx-auto container mt-10">{t('salles_subtitle')}</h1>
        <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto ">
          <img alt="Chapiteaux de presentation" src="./images/carousel/salles_carousel7.jpg" />
          <div className="flex flex-col justify-center">
            <div className="text-justify">{t('salles_paragraph1')}</div>
            <div className="text-justify">{t('salles_paragraph2')}</div>
            <div className="text-justify">{t('salles_paragraph3')}</div>
            <div className="text-justify">{t('salles_paragraph4')}</div>
          </div>
        </div>
      </div>
      <div className="bg-amber-800 px-20 py-10 text-white my-20 ">
        <div className="mb-5 font-bold mx-auto container">{t('salles_highlighted_text')}</div>
        <ul className="list-disc mx-auto container">
          <li>{t('salles_list_item1')}</li>
          <li>{t('salles_list_item2')}</li>
          <li>{t('salles_list_item3')}</li>
        </ul>
      </div>
      <div className="px-5">
        <div>
          <h1 className="uppercase text-3xl font-extrabold text-black text-center m-4 p-5">{t('salles_name_1')}</h1>
          <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto m-2">
            <Carousel className="md:w-4/5 h-52 mx-auto">
              <img src="./images/carousel/salles_carousel.jpg" alt="Carousel 1" />
              <img src="./images/carousel/salles_carousel4.jpg" alt="Carousel 2" />
              <img src="./images/carousel/salles_carousel2.jpg" alt="Carousel 3" />
              <img src="./images/carousel/salles_carousel3.jpg" alt="Carousel 4" />
            </Carousel>
            <div className="text-justify">{t('salles_description_1')}</div>
          </div>
        </div>

        <div>
          <h1 className="uppercase text-3xl font-extrabold text-black text-center m-4 p-5">{t('salles_name_2')}</h1>
          <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto  m-4">
            <Carousel className="md:w-4/5 mx-auto h-56">
              <img src="./images/carousel/salles_carousel5.jpg" alt="Carousel 1" />
              <img src="./images/carousel/salles_carousel6.jpg" alt="Carousel 2" />
              <img src="./images/carousel/salles_carousel2.jpg" alt="Carousel 3" />
              <img src="./images/carousel/salles_carousel3.jpg" alt="Carousel 4" />
            </Carousel>
            <div className="flex flex-col justify-center text-justify">{t('salles_description_2')}</div>
          </div>
        </div>

        <div>
          <h1 className="uppercase text-3xl font-extrabold text-black text-center m-4 p-5">{t('salles_name_3')}</h1>
          <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto  m-2">
            <Carousel className="md:w-4/5 mx-auto h-56">
              <img src="./images/carousel/salles_carousel3.jpg" alt="Carousel 1" />
              <img src="./images/carousel/salles_carousel6.jpg" alt="Carousel 2" />
              <img src="./images/carousel/salles_carousel7.jpg" alt="Carousel 3" />
              <img src="./images/carousel/salles_carousel4.jpg" alt="Carousel 4" />
            </Carousel>
            <div className="flex flex-col justify-center text-justify">{t('salles_description_3')}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 text-center gap-20 mx-auto container">
          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faUsers} size="2x" className="mb-2" />
            <div>{t('salles_card1_description')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faCar} size="2x" className="mb-2" />
            <div>{t('salles_card2_description')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faSmoking} size="2x" className="mb-2" />
            <div>{t('salles_card3_description')}</div>
          </Card>
        </div>
        <Carousel className="md:w-3/5 mx-auto" style={{ height: '450px' }}>
          <img src="./images/carousel/salles_carousel.jpg" alt="Carousel 1" />
          <img src="./images/carousel/salles_carousel2.jpg" alt="Carousel 3" />
          <img src="./images/carousel/salles_carousel3.jpg" alt="Carousel 4" />
          <img src="./images/carousel/salles_carousel4.jpg" alt="Carousel 1" />
          <img src="./images/carousel/salles_carousel5.jpg" alt="Carousel 2" />
          <img src="./images/carousel/salles_carousel6.jpg" alt="Carousel 3" />
          <img src="./images/carousel/salles_carousel7.jpg" alt="Carousel 4" />
        </Carousel>
      </div>
    </div>
  )
}

export default SallesContent
